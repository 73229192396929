<template>
  <div
    id="mychart"
    class="echart"
    :style="myChartStyle"
  />
</template>

<script>
import * as echarts from 'echarts'
import { getFeedbackTypeCharList } from '@/api/Userfeedback.js'
export default {
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    statusList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      freeTypeList: [],
      xData: [], // ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // 横坐标
      series: [],
      myChartStyle: { float: 'left', width: '100%', height: '400px' } // 图表样式
    }
  },
  created () {
    this.statusList.forEach((item, id) => {
      this.freeTypeList.push(item.name)
    })
  },
  mounted () {
    this.nuxtList()
  },
  methods: {
    nuxtList () {
      console.log('formData', this.formData)
      this.series = []
      this.xData = []
      this.serie = []
      getFeedbackTypeCharList({
        ...this.formData
      })
        .then(res => {
          if (res.code === 200) {
            if (res.data.list) {
              res.data.list.forEach(item => {
                this.xData.push(item.date)
              })
              for (var i = 0; i < res.data.list[0].list.length; i++) {
                const item = res.data.list[0].list[i]
                const obj = {
                  name: item.typeName,
                  type: 'bar',
                  emphasis: {
                    focus: 'series'
                  },
                  data: []
                }
                this.series.push(obj)
              }
              for (var t = 0; t < this.series.length; t++) {
                this.serie = this.series[t]
                for (var x = 0; x < res.data.list.length; x++) {
                  const list = res.data.list[x].list
                  for (var y = 0; y < list.length; y++) {
                    const d = list[y]
                    if (this.serie.name === d.typeName) {
                      this.serie.data.push(d.count)
                    }
                  }
                }
              }
            }
          }
        })
      console.log(this.series, this.freeTypeList, this.xData)

      setTimeout(() => {
        this.initEcharts()
      }, 500)
    },
    initEcharts () {
      // 多列柱状图
      const mulColumnZZTData = {
        xAxis: {
          data: this.xData
        },
        // 图例
        legend: {
          data: this.freeTypeList, // this.freeTypeList,
          top: '0%'
        },
        yAxis: {},
        series: this.series
      }
      const myChart = echarts.init(document.getElementById('mychart'))
      myChart.clear()
      myChart.setOption(mulColumnZZTData)
      // 随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>
